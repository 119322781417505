"use client"
import { NavLinks } from "./nav-links";
import { NavSearch } from "./nav-search";
import React, { useState, useRef } from 'react';
import Image from "next/image";
import { useIsVisible } from "../hooks/useIsVisible";
//if client use
import { useTranslations } from "next-intl";

export const Services = () => {
  const t = useTranslations("Services");

  const ref1 = useRef<HTMLDivElement>(null);
  const isVisible1 = useIsVisible(ref1);


  const ref2 = useRef<HTMLDivElement>(null);
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef<HTMLDivElement>(null);
  const isVisible3 = useIsVisible(ref3);

  const ref4 = useRef<HTMLDivElement>(null);
  const isVisible4 = useIsVisible(ref4);


  const ref5 = useRef<HTMLDivElement>(null);
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef<HTMLDivElement>(null);
  const isVisible6 = useIsVisible(ref6);


  return (

<>
<div className="w-screen bg-bitben-violet pt-8" style={{paddingBottom:"8rem"}}>
<div className="grid md:grid-cols-3 max-w-screen-lg mx-auto gap-10 mt-16 px-5 ">
      <div className="col-span-3">
        <h2 className="text-3xl md:text-4xl lg:text-5xl text-center mb-4 text-slate-200">{t("title0")}<span className='font-semibold italic'>{t("title1")}</span>{t("title2")}</h2>
        {/* <p className="text-lg md:text-xl lg:text-2xl text-center text-slate-300">{t("description")}</p> */}
      </div>
    </div>


  <div className="grid md:grid-cols-3 max-w-screen-lg mx-auto gap-10 mt-16 px-5" >
    <div ref={ref1} className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible1 ? "opacity-100" : "opacity-0"}`} >
          {/* webdev icon */}

          <div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 250"
            className="w-full h-full"
          >
<g id="coding_5">
	<g id="Group_139">
		<path id="Vector_147" fill="#9AC439" d="M78.21,0h78.3v13.347h-78.3V0z"/>
		<path id="Vector_148" fill="#9AC439" d="M55.85,0h11.23v13.347H55.85V0z"/>
		<path id="Vector_149" fill="#9AC439" d="M156.51,24.477H33.49v76.184h123.02V24.477z M73.36,48.819h-2.41
			c-1.96,0-3.561,1.597-3.561,3.56c0,3.445-1.189,6.618-3.189,9.126c2,2.509,3.189,5.681,3.189,9.127
			c0,1.963,1.601,3.561,3.561,3.561h2.41V85.33h-2.41c-8.101,0-14.69-6.6-14.69-14.698c0-1.963-1.6-3.56-3.56-3.56h-2.42V55.939
			h2.42c1.96,0,3.56-1.598,3.56-3.561c0-8.102,6.59-14.693,14.69-14.693h2.41V48.819z M90.32,84.68l-10.09-4.71L99.68,38.328
			l10.09,4.71L90.32,84.68z M140.439,67.072h-2.409c-1.97,0-3.561,1.597-3.561,3.56c0,8.099-6.6,14.698-14.7,14.698h-2.409V74.192
			h2.409c1.971,0,3.561-1.598,3.561-3.561c0-3.446,1.189-6.618,3.189-9.127c-2-2.508-3.189-5.681-3.189-9.126
			c0-1.963-1.59-3.56-3.561-3.56h-2.409V37.686h2.409c8.101,0,14.7,6.592,14.7,14.693c0,1.963,1.591,3.561,3.561,3.561h2.409V67.072
			z"/>
		<path id="Vector_150" fill="#9AC439" d="M33.49,0H44.72v13.347H33.49V0z"/>
		<path id="Vector_151" fill="#FFFFFF" d="M190,166.24V145.27H0v20.971h78.23v12.62H50.28v11.13h89.439v-11.13h-27.95v-12.62H190z"
			/>
		<path id="Vector_152" fill="#FFFFFF" d="M190,134.13V24.477h-22.36v87.313H22.36V24.477H0V134.13H190z"/>
	</g>
</g>
</svg>
</div>
        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_customized_websolutions_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_customized_websolutions_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="/services/website-development" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-bitben-green hover:text-bitben-violet">
              {t("service_customized_websolutions_link")}
            </a>
        </div>
    </div>

    <div  
    ref={ref2}
    className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible2 ? "opacity-100" : "opacity-0"}`}
    style={{ transitionDelay: isVisible1 ? "0.2s" : "0s" }}
    >
      <div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 250"
            className="w-full h-full"
          >
    <g id="data_network">
	<g id="Group_5">
		<path id="Vector_59" fill="#FFFFFF" d="M114.19,174.637h15.66v13.424H88.49v-13.424h15.64V56.849H67v117.788h15.64v13.424H41.31
			v-13.424h15.63V56.849H19.79v117.788h15.64v19.303h100.3v-19.303h15.64V56.849h-37.18V174.637z M39.03,74.546
			c4.16,0,7.49,3.364,7.49,7.485s-3.36,7.454-7.49,7.454c-4.12,0-7.45-3.363-7.45-7.454C31.55,77.91,34.91,74.546,39.03,74.546z
			 M29.4,126h17.91v5.879H29.4V126z M29.4,140h17.91v5.879H29.4V140z M29.4,159.91v-5.88h17.91v5.88H29.4z M86.25,74.546
			c4.12,0,7.45,3.364,7.45,7.485s-3.36,7.454-7.45,7.454c-4.15,0-7.49-3.363-7.49-7.454C78.76,77.91,82.1,74.546,86.25,74.546z
			 M76.61,126h17.91v5.879H76.61V126z M76.61,140h17.91v5.879H76.61V140z M76.61,159.91v-5.88h17.91v5.88H76.61z M133.43,74.546
			c4.15,0,7.48,3.364,7.48,7.485s-3.36,7.454-7.48,7.454c-4.119,0-7.459-3.363-7.459-7.454
			C125.97,77.91,129.311,74.546,133.43,74.546z M123.82,126h17.91v5.879h-17.91V126z M123.82,140h17.91v5.879h-17.91V140z
			 M123.82,154.03h17.91v5.88h-17.91V154.03z"/>
		<path id="Vector_60" fill="#9AC439" d="M144.55,30.909C141.28,13.152,125.64,0,107.4,0C96.94,0,87.03,4.333,79.88,11.909
			c-3.97-1.394-8.15-2.091-12.45-2.091c-13.24,0-25.49,6.91-32.33,18.152C15.28,29.303,0,45.546,0,65.637
			c0,0.969,0.03,1.909,0.1,2.878c0.69,9.213,6.12,17.182,13.84,21.697V50.97h143.31v39.455c8.24-4.849,13.85-13.667,13.939-23.758
			v-0.303C171.16,49.818,160.311,35.485,144.55,30.909L144.55,30.909z"/>
	</g>
</g>
</svg>
</div>

        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_webhosting_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_webhosting_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="/services/webhosting" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-slate-300 hover:text-bitben-violet">
              {t("service_webhosting_link")}
            </a>
        </div>
    </div>


    <div
    ref={ref3}
    className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible3 ? "opacity-100" : "opacity-0"}`}
    style={{ transitionDelay: isVisible2 ? "0.2s" : "0s" }}
    >
        {/* <span className="text-bitben-green bg-violet-500/10 p-3 rounded-full ">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5"><path d="M9.96424 2.68571C10.0668 2.42931 9.94209 2.3833 9.6857 2.03577C9.4293 1.93322 9.13832 2.05792 9.03576 2.31432L5.03576 12.3143C4.9332 12.5707 5.05791 12.8617 5.3143 12.9642C5.5707 13.0668 5.86168 12.9421 5.96424 12.6857L9.96424 2.68571ZM3.85355 5.14646C4.04882 5.34172 4.04882 5.6583 3.85355 5.85356L2.20711 7.50001L3.85355 9.14646C4.04882 9.34172 4.04882 9.6583 3.85355 9.85356C3.65829 10.0488 3.34171 10.0488 3.14645 9.85356L1.14645 7.85356C0.951184 7.6583 0.951184 7.34172 1.14645 7.14646L3.14645 5.14646C3.34171 4.9512 3.65829 4.9512 3.85355 5.14646ZM11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646L13.8536 7.14646C14.0488 7.34172 14.0488 7.6583 13.8536 7.85356L11.8536 9.85356C11.6583 10.0488 11.3417 10.0488 11.1464 9.85356C10.9512 9.6583 10.9512 9.34172 11.1464 9.14646L12.7929 7.50001L11.1464 5.85356C10.9512 5.6583 10.9512 5.34172 11.1464 5.14646Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
        </span> */}
 <div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 250"
            className="w-full h-full"
          >
<g id="cpu">
	<g id="Group_128">
		<path id="Vector_98" fill="#FFFFFF" d="M170.98,70.971V40.874h-10.02v20.078h-15.53l-10.02-10.019H120.56V40.837h27.799V9.445
			h-10.02v21.373h-27.8v20.115H90.5v-31.47H80.48v31.47H60.439V30.818H32.641V9.445H22.62v31.392h27.81v10.097H35.57L25.55,60.952
			H10.02V40.874H0v30.097h22.62V89.93H10.02v10.02H22.62v18.95H0V149h10.02v-20.08h15.53l10.021,10.02H50.43v10.102H22.62v31.391
			h10.021v-21.381H60.44v-20.109h20.04v31.471H90.5v-31.471h20.04v20.109h27.8v21.381h10.02v-31.391h-27.799v-10.102h14.851
			l10.02-10.02h15.53V149h10.021v-30.1h-22.621V99.95h12.602V89.93h-12.602V70.971H170.98z M67.561,105.561l-7.08,7.09L42.77,94.94
			l17.71-17.71l7.08,7.079L56.939,94.94L67.561,105.561z M85.33,118.721l-9.78-2.181l10.1-45.388l9.779,2.176L85.33,118.721z
			 M110.5,112.65l-7.08-7.09l10.62-10.621l-10.62-10.63l7.08-7.079l17.71,17.71L110.5,112.65z"/>
		<path id="Vector_99" fill="#9AC439" d="M161.09,21.602h10.01V31.62h-10.01V21.602z"/>
		<path id="Vector_100" fill="#9AC439" d="M0,22.047h10.02v10.018H0V22.047z"/>
		<path id="Vector_101" fill="#9AC439" d="M161.09,157.48h10.01v10.02h-10.01V157.48z"/>
		<path id="Vector_102" fill="#9AC439" d="M0,157.939h10.02v10.021H0V157.939z"/>
		<path id="Vector_103" fill="#9AC439" d="M80.54,179.98h10.021V190H80.54V179.98z"/>
		<path id="Vector_104" fill="#9AC439" d="M80.54,0h10.021v10.019H80.54V0z"/>
	</g>
</g>
</svg>
</div>

        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_software_development_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_software_development_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="/services/software-development" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-slate-300 hover:text-bitben-violet">
              {t("service_software_development_link")}
            </a>
        </div>
    </div>
</div>
<div className="grid md:grid-cols-2 max-w-screen-lg mx-auto gap-10 mt-16 px-5">
    {/* <div ref={ref4}
className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible4 ? "opacity-100" : "opacity-0"}`}
style={{ transitionDelay: isVisible3 ? "0.2s" : "0s" }}
>
<span className="text-bitben-green bg-violet-500/10 p-3 rounded-full ">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5"><path d="M9.96424 2.68571C10.0668 2.42931 9.94209 2.3833 9.6857 2.03577C9.4293 1.93322 9.13832 2.05792 9.03576 2.31432L5.03576 12.3143C4.9332 12.5707 5.05791 12.8617 5.3143 12.9642C5.5707 13.0668 5.86168 12.9421 5.96424 12.6857L9.96424 2.68571ZM3.85355 5.14646C4.04882 5.34172 4.04882 5.6583 3.85355 5.85356L2.20711 7.50001L3.85355 9.14646C4.04882 9.34172 4.04882 9.6583 3.85355 9.85356C3.65829 10.0488 3.34171 10.0488 3.14645 9.85356L1.14645 7.85356C0.951184 7.6583 0.951184 7.34172 1.14645 7.14646L3.14645 5.14646C3.34171 4.9512 3.65829 4.9512 3.85355 5.14646ZM11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646L13.8536 7.14646C14.0488 7.34172 14.0488 7.6583 13.8536 7.85356L11.8536 9.85356C11.6583 10.0488 11.3417 10.0488 11.1464 9.85356C10.9512 9.6583 10.9512 9.34172 11.1464 9.14646L12.7929 7.50001L11.1464 5.85356C10.9512 5.6583 10.9512 5.34172 11.1464 5.14646Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
        </span>
        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_api_development_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_api_development_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="#" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-slate-300 hover:text-bitben-violet">
            {t("service_api_development_link")}
            </a>
        </div>
    </div> */}

    <div ref={ref5}
className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible5 ? "opacity-100" : "opacity-0"}`}
style={{ transitionDelay: isVisible4 ? "0.2s" : "0s" }}>
        <div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 250"
            className="w-full h-full"
          >
<g id="analytics">
	<path id="Vector_55" fillRule="evenodd" clipRule="evenodd" fill="#9AC439" d="M33.959,170.536h119.582
		c2.152,0,3.906-1.754,3.906-3.905v-5.915c0-5.402-5.531-9.821-12.292-9.821h-21.941v-22.901h49.872
		c7.929,0,14.414-6.486,14.414-14.415V14.415C187.5,6.487,181.015,0,173.086,0H14.414C6.486,0,0,6.487,0,14.415v99.163
		c0,7.929,6.486,14.415,14.414,14.415h49.872v22.9H42.345c-6.761,0-12.292,4.42-12.292,9.821v5.916
		C30.053,168.782,31.807,170.536,33.959,170.536L33.959,170.536z M54.72,22.631c10.535,1.39,18.876,9.732,20.265,20.267
		L54.72,42.892V22.631z M74.983,49.129c-0.828,6.259-4.108,11.743-8.846,15.453l-9.086-15.458L74.983,49.129z M60.773,67.742
		c-2.818,1.19-5.915,1.847-9.165,1.847c-13.024,0-23.582-10.557-23.582-23.581c0-11.969,8.917-21.854,20.469-23.377V46
		c0,0.556,0.146,1.104,0.428,1.585L60.773,67.742z M151.97,40.118c4.144,0,7.504,3.36,7.504,7.504s-3.359,7.504-7.504,7.504
		c-1.099,0-2.142-0.236-3.082-0.66l-17.099,17.843c0.51,1.014,0.799,2.159,0.799,3.371c0,4.144-3.36,7.504-7.504,7.504
		c-4.145,0-7.504-3.36-7.504-7.504c0-0.297,0.017-0.59,0.051-0.878l-14.203-7.006c-1.351,1.315-3.197,2.126-5.232,2.126
		c-1.337,0-2.593-0.351-3.681-0.964L78.293,83.087c0.335,0.851,0.519,1.779,0.519,2.749c0,4.144-3.359,7.504-7.503,7.504
		s-7.504-3.36-7.504-7.504s3.36-7.504,7.504-7.504c1.337,0,2.593,0.35,3.681,0.964l16.222-14.129
		c-0.335-0.851-0.519-1.779-0.519-2.749c0-4.144,3.359-7.504,7.503-7.504s7.504,3.359,7.504,7.504c0,0.297-0.018,0.59-0.052,0.878
		l14.204,7.006c1.351-1.315,3.196-2.125,5.231-2.125c1.099,0,2.143,0.236,3.083,0.66l17.099-17.843
		c-0.51-1.014-0.799-2.159-0.799-3.371C144.466,43.478,147.826,40.118,151.97,40.118L151.97,40.118z M14.053,12.054h159.394
		c1.1,0,1.999,0.9,1.999,1.999v89.659H12.054V14.053C12.054,12.954,12.953,12.054,14.053,12.054L14.053,12.054z"/>
	<path id="Vector_56" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M54.72,22.63
		c10.535,1.389,18.876,9.731,20.265,20.266L54.72,42.891V22.63z M74.983,49.128c-0.828,6.258-4.108,11.742-8.846,15.453
		l-9.086-15.458L74.983,49.128z M60.773,67.741c-2.818,1.189-5.915,1.847-9.165,1.847c-13.024,0-23.582-10.558-23.582-23.582
		c0-11.968,8.917-21.853,20.469-23.377v23.369c0,0.556,0.146,1.104,0.428,1.585L60.773,67.741z M151.97,40.117
		c4.144,0,7.504,3.359,7.504,7.504c0,4.144-3.359,7.504-7.504,7.504c-1.099,0-2.142-0.236-3.083-0.661l-17.098,17.843
		c0.51,1.014,0.798,2.159,0.798,3.372c0,4.144-3.359,7.503-7.503,7.503c-4.145,0-7.504-3.359-7.504-7.503
		c0-0.298,0.017-0.591,0.051-0.879l-14.203-7.005c-1.351,1.315-3.197,2.125-5.232,2.125c-1.337,0-2.593-0.35-3.681-0.964
		l-16.222,14.13c0.335,0.851,0.519,1.778,0.519,2.748c0,4.145-3.359,7.504-7.503,7.504s-7.504-3.359-7.504-7.504
		c0-4.144,3.36-7.504,7.504-7.504c1.337,0,2.593,0.351,3.681,0.964l16.222-14.129c-0.335-0.851-0.519-1.778-0.519-2.749
		c0-4.144,3.359-7.503,7.503-7.503s7.504,3.359,7.504,7.503c0,0.298-0.018,0.591-0.052,0.879l14.204,7.005
		c1.351-1.315,3.196-2.125,5.231-2.125c1.099,0,2.143,0.236,3.083,0.661l17.099-17.844c-0.51-1.013-0.799-2.158-0.799-3.371
		C144.466,43.476,147.826,40.117,151.97,40.117z"/>
</g>
</svg>
</div>
        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_iot_solutions_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_iot_solutions_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="/services/iot" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-slate-300 hover:text-bitben-violet">
              {t("service_iot_solutions_link")}
            </a>
        </div>
    </div>


    <div ref={ref6}
className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible6 ? "opacity-100" : "opacity-0"}`}
style={{ transitionDelay: isVisible5 ? "0.2s" : "0s" }}>
    <div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 250"
            className="w-full h-full"
          >
<g id="pencil_case">
	<g id="Group_32">
		<g id="Group_33">
			<path id="Vector_85" fill="#9AC439" d="M156.873,6.097c-1.278-2.568-4.313-3.683-6.961-2.537
				c-4.909,2.125-29.596,13.39-34.221,26.105c-5.103,13.999,2.115,29.434,16.1,34.523c13.901,5.069,29.431-2.106,34.524-16.099
				C170.939,35.375,159.263,10.873,156.873,6.097z"/>
		</g>
	</g>
	<g id="Group_34">
		<g id="Group_35">
			<path id="Vector_86" fill="#FFFFFF" d="M147.908,108.134l-1.352,10.809c1.931,0.817,3.292,2.734,3.292,4.964v21.549
				c0,2.972-2.418,5.388-5.389,5.388h-1.894l-1.352,11.134h3.245c8.91,0,16.164-7.61,16.164-16.521v-21.549
				C160.624,116.18,155.163,109.72,147.908,108.134z"/>
		</g>
	</g>
	<g id="Group_36">
		<g id="Group_37">
			<path id="Vector_87" fill="#FFFFFF" d="M21.182,96.972l9.011,72.758c1.002,8.071,7.898,14.158,16.035,14.158h67.174
				c8.128,0,15.033-6.087,16.035-14.158l9.002-72.758H21.182z"/>
		</g>
	</g>
	<g id="Group_38">
		<g id="Group_39">
			<path id="Vector_88" fill="#9AC439" d="M90.021,2.753c-1.83-3.651-7.806-3.651-9.645,0l-9.286,29.569h28.217L90.021,2.753z"/>
		</g>
	</g>
	<g id="Group_40">
		<g id="Group_41">
			<path id="Vector_89" fill="#9AC439" d="M101.357,43.099H69.039v43.099h32.318V43.099z"/>
		</g>
	</g>
	<g id="Group_42">
		<g id="Group_43">
			<path id="Vector_90" fill="#9AC439" d="M128.094,74.315c-5.351-1.952-10.003-5.119-13.883-9.071l-2.078,5.694v15.261h28.843
				l3.604-9.889C139.128,76.839,134.172,76.525,128.094,74.315z"/>
		</g>
	</g>
	<g id="Group_44">
		<g id="Group_45">
			<path id="Vector_91" fill="#9AC439" d="M41.814,3.995c-0.763-2.877-3.714-4.583-6.602-3.808L3.998,8.776
				C1.12,9.543-0.581,12.5,0.182,15.373l4.376,16.32l15.419-4.894c2.877-0.757,5.838,0.937,6.601,3.81
				c0.763,2.872-0.937,5.828-3.815,6.597L7.345,42.1l2.795,10.411l15.419-4.894c2.887-0.785,5.83,0.931,6.592,3.808
				c0.773,2.874-0.938,5.829-3.806,6.598l-15.419,4.895l2.786,10.406l15.419-4.895c2.905-0.773,5.829,0.932,6.601,3.809
				c0.763,2.873-0.938,5.83-3.806,6.597L18.498,83.73l0.662,2.466h39.104V65.609L41.814,3.995z"/>
		</g>
	</g>
</g>
</svg>
</div>
        <div>
            <h3 className="font-semibold text-xl text-bitben-green">{t("service_ci_design_title")}</h3>
            <p className="mt-1 text-slate-300">{t("service_ci_design_description")}</p>
        </div>
        <div className="flex mt-8">
            <a href="/services/grafic-design" className="uppercase py-2 px-4 rounded-lg bg-bitben-green border-2 border-transparent text-bitben-violet text-md mr-4 hover:bg-slate-300 hover:text-bitben-violet">
            {t("service_ci_design_link")}
            </a>
        </div>
    </div>
</div>

</div>
</>
  );
};