"use client"
import { NavLinks } from "./nav-links";
import { NavSearch } from "./nav-search";
import React, { useState } from 'react';
import Image from "next/image";
import RocketImage from '../../assets/grafics/rocket.svg';
import { useTranslations } from "next-intl";

export const CallToActionBannerWithImage = () => {
    
  const t = useTranslations('CallToActionBannerHomePage');

  return (
    <>
    <div className=" max-w-screen-lg  mx-auto  bg-white px-4 md:px-6">
    <div className="items-center mx-auto md:grid md:grid-cols-2 sm:py-16 lg:px-6">
      <div className="mb-8 md:mb-0 pt-12">
        <Image
          priority={true}
          src={RocketImage}
          alt="Persönliches Angebot"
        />
      </div>
      <div className="mt-4 md:mt-0">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-bitben-violet">
          {t("title")}
        </h2>
        <p className="mb-6 text-bitben-violet md:text-lg">
          {t("description")}
        </p>
        <a href="/inquiry" className="inline-flex items-center text-white bg-bitben-violet hover:bg-bitben-green hover:text-bitben-violet focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
          {t("button_text")}
          <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </a>
    </div>
  </div>
</div>

    </>
  );
};