"use client"
import { RefObject, useRef } from "react";
import { useIsVisible } from "../hooks/useIsVisible";
import {unstable_setRequestLocale, getTranslations} from 'next-intl/server';
//if client use
import { useTranslations } from "next-intl";

export default function Pros() {


  const t = useTranslations("Pros");
  // const messages = useMessages();
  


  const ref1 = useRef<HTMLDivElement>(null);
  const isVisible1 = useIsVisible(ref1);


  const ref2 = useRef<HTMLDivElement>(null);
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef<HTMLDivElement>(null);
  const isVisible3 = useIsVisible(ref3);

  const ref4 = useRef<HTMLDivElement>(null);
  const isVisible4 = useIsVisible(ref4);


  const ref5 = useRef<HTMLDivElement>(null);
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef<HTMLDivElement>(null);
  const isVisible6 = useIsVisible(ref6);

  return (
    <>
       

    <div className="grid md:grid-cols-3 max-w-screen-lg mx-auto gap-10 mt-16 px-5">
      <div className="col-span-3">
        <h2 className="text-3xl md:text-4xl lg:text-5xl text-center mb-4 text-bitben-violet">{t("title0")}<span className='font-semibold italic'>{t("title1")}</span>{t("title2")}</h2>
        <p className="text-lg md:text-xl lg:text-2xl text-center text-bitben-violet">{t("pro_support_description")}</p>
      </div>
    </div>


  <div className="grid md:grid-cols-3 max-w-screen-lg mx-auto gap-10 mt-16 px-5" >
    <div ref={ref1} className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible1 ? "opacity-100" : "opacity-0"}`} >
    <div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 250"
            className="w-full h-full"
          >
            <g id="idea">
              <g id="Group_78">
                <g id="Group_79">
                  <path
                    id="Vector_122"
                    fill="#43277F"
                    d="M86.076,139.088h56.62c1.729-10.954,8.29-20.347,15.21-30.197
                          c8.71-12.394,17.71-25.211,17.71-42.167c0-33.762-27.47-61.23-61.23-61.23c-33.76,0-61.229,27.468-61.229,61.23
                          c0,16.955,9,29.773,17.71,42.167C77.786,118.741,84.346,128.134,86.076,139.088z M132.716,59.527l7.87-7.871l15.07,15.068
                          l-15.07,15.069l-7.87-7.872l7.2-7.197L132.716,59.527z M108.816,38.892h11.14v55.664h-11.14V38.892z M88.187,51.656l7.869,7.871
                          l-7.199,7.197l7.199,7.198l-7.869,7.871l-15.07-15.069L88.187,51.656z"
                  />
                  <path
                    id="Vector_123"
                    fill="#9AC439"
                    d="M86.556,150.221h55.66v23.007h-55.66V150.221z"
                  />
                </g>
                <path
                  id="Vector_124"
                  fill="#9AC439"
                  d="M97.687,184.362h33.399v11.133H97.687V184.362z"
                />
              </g>
            </g>
          </svg>
      </div>
        <div>
            <h3 className="font-semibold text-xl text-bitben-violet">{t("pro_customized_solutions_title")}</h3>
            <p className="mt-1 text-bitben-violet">{t("pro_customized_solutions_description")}</p>
        </div>
    </div>
    

    <div  
    ref={ref2}
    className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible2 ? "opacity-100" : "opacity-0"}`}
    style={{ transitionDelay: isVisible1 ? "0.2s" : "0s" }}
    >
          {/* costs icon */}

          <div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 250"
            className="w-full h-full"
          >
<g id="transfer_2">
	<path id="Vector_85" fill="#43277D" d="M9.38,106.25c0-10.356,8.39-18.75,18.74-18.75c10.36,0,18.76,8.394,18.76,18.75
		c0,10.355-8.399,18.75-18.76,18.75C17.77,125,9.38,116.605,9.38,106.25z"/>
	<path id="Vector_86" fill="#43277D" d="M56.25,159.375v12.5c0,1.725-1.4,3.125-3.13,3.125h-50C1.4,175,0,173.6,0,171.875v-12.5
		c0-15.531,12.59-28.125,28.12-28.125C43.66,131.25,56.25,143.844,56.25,159.375z"/>
	<path id="Vector_87" fill="#43277D" d="M146.88,125c-10.36,0-18.76-8.395-18.76-18.75c0-10.356,8.399-18.75,18.76-18.75
		c10.351,0,18.74,8.394,18.74,18.75C165.62,116.605,157.23,125,146.88,125z"/>
	<path id="Vector_88" fill="#43277D" d="M146.88,131.25c15.53,0,28.12,12.594,28.12,28.125v12.5c0,1.725-1.4,3.125-3.12,3.125h-50
		c-1.729,0-3.13-1.4-3.13-3.125v-12.5C118.75,143.844,131.34,131.25,146.88,131.25z"/>
	<path id="Vector_89" fill="#9AC439" d="M103.13,124.999H57.21c-0.85,0-1.28-1.031-0.67-1.637l23.08-23.081
		c0.6-0.601,1.63-0.176,1.63,0.681v11.537h21.88c1.72,0,3.12,1.4,3.12,3.125v6.25C106.25,123.599,104.85,124.999,103.13,124.999z"/>
	<path id="Vector_90" fill="#9AC439" d="M112.22,139.137l-23.08,23.082c-0.609,0.601-1.64,0.175-1.64-0.681V150H65.62
		c-1.72,0-3.12-1.4-3.12-3.125v-6.25c0-1.725,1.4-3.125,3.12-3.125h45.92C112.39,137.5,112.82,138.531,112.22,139.137z"/>
	<path id="Vector_91" fill="#9AC439" d="M87.5,0C63.34,0,43.75,19.588,43.75,43.75S63.34,87.5,87.5,87.5s43.75-19.588,43.75-43.75
		S111.66,0,87.5,0z M106.25,28.125c0,1.725-1.4,3.125-3.13,3.125H84.38c-1.729,0-3.13,1.4-3.13,3.125s1.4,3.125,3.13,3.125h6.24
		c9.16,0,16.5,7.912,15.55,17.256c-0.75,7.294-6.84,12.725-13.98,13.769v7.725c0,1.037-0.84,1.875-1.879,1.875h-5.621
		c-1.039,0-1.879-0.838-1.879-1.875v-7.5H71.88c-1.729,0-3.13-1.4-3.13-3.125v-6.25c0-1.725,1.4-3.125,3.13-3.125h18.74
		c1.729,0,3.13-1.4,3.13-3.125S92.35,50,90.62,50H85.3c-8.17,0-15.64-5.862-16.46-13.994c-0.9-8.8,5.58-16.237,13.98-17.087V11.25
		c0-1.038,0.84-1.875,1.869-1.875h5.631c1.04,0,1.869,0.837,1.869,1.875v7.5h10.94c1.72,0,3.12,1.4,3.12,3.125V28.125z"/>
	<circle fill="#9AC439" cx="87.5" cy="42.875" r="38.125"/>
</g>
<g>
	<path fill="#43277D" d="M93.173,25.753c-3.502,0-6.442,0.844-8.818,2.533c-1.418,1.001-2.7,2.418-3.847,4.253
		c-1.167,1.897-1.855,3.701-2.063,5.41h21.327l-0.844,4.19H77.944c-0.021,0.438-0.031,0.855-0.031,1.251
		c0,1.605,0.01,2.564,0.031,2.877h20.139l-0.875,4.19H78.601c0.875,4.774,2.981,8.162,6.316,10.163
		c2.397,1.438,4.993,2.158,7.787,2.158c3.898,0,6.942-1.021,9.132-3.064v6.442c-2.606,1.208-5.483,1.813-8.631,1.813
		c-9.424,0-15.792-3.919-19.107-11.758c-0.667-1.584-1.241-3.502-1.72-5.754h-4.784l0.875-4.19h3.284
		c-0.063-0.876-0.094-1.824-0.094-2.846c0-0.417,0.01-0.844,0.031-1.282h-4.097l0.875-4.19h3.627
		c1.292-6.796,4.66-11.675,10.101-14.635c3.356-1.835,7.057-2.752,11.101-2.752c3.878,0,7.141,0.844,9.788,2.533l-1.25,5.816
		C99.292,26.806,96.405,25.753,93.173,25.753z"/>
</g>
</svg>
</div>
        <div>
            <h3 className="font-semibold text-xl text-bitben-violet">{t("pro_no_hidden_costs_title")}</h3>
            <p className="mt-1 text-bitben-violet">{t("pro_no_hidden_costs_description")}</p>
        </div>
    </div>


    <div
    ref={ref3}
    className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible3 ? "opacity-100" : "opacity-0"}`}
    style={{ transitionDelay: isVisible2 ? "0.2s" : "0s" }}
    >
       
       <div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            className="w-full h-full"
          >

       <g id="blockchain">
	<g id="Group_38">
		<path id="Vector_51" fill="#43277D" d="M50.28,55.66v46.02L89.43,78.74V32.72L50.28,55.66z"/>
		<path id="Vector_52" fill="#43277D" d="M44.72,0L5.45,23.01l39.27,23.01L83.99,23.01L44.72,0z"/>
		<path id="Vector_53" fill="#43277D" d="M39.15,55.66L0,32.72V78.74l39.15,22.939V55.66z"/>
		<path id="Vector_54" fill="#43277D" d="M190,32.72L150.85,55.66v46.02L190,78.74V32.72z"/>
		<path id="Vector_55" fill="#43277D" d="M139.72,55.66l-39.15-22.94V78.74l39.15,22.939V55.66z"/>
		<path id="Vector_56" fill="#43277D" d="M145.28,0l-39.27,23.01l39.27,23.01l39.271-23.01L145.28,0z"/>
		<path id="Vector_57" fill="#9AC439" d="M145.28,139.22c6.15,0,11.13-4.989,11.13-11.13c0-6.15-4.98-11.14-11.13-11.14
			c-6.15,0-11.13,4.989-11.13,11.14C134.15,134.23,139.13,139.22,145.28,139.22z"/>
		<path id="Vector_58" fill="#9AC439" d="M44.72,139.22c6.15,0,11.13-4.989,11.13-11.13c0-6.15-4.98-11.14-11.13-11.14
			c-6.15,0-11.13,4.989-11.13,11.14C33.59,134.23,38.57,139.22,44.72,139.22z"/>
		<path id="Vector_59" fill="#9AC439" d="M123.73,122.52H66.28c0.46,1.78,0.71,3.641,0.71,5.57c0,1.92-0.25,3.78-0.71,5.561h57.45
			c-0.46-1.78-0.71-3.641-0.71-5.561C123.02,126.16,123.27,124.3,123.73,122.52z"/>
	</g>
</g>
</svg>
</div>
        <div>
        <h3 className="font-semibold text-xl text-bitben-violet">{t("pro_fast_infrastructure_title")}</h3>
            <p className="mt-1 text-bitben-violet">{t("pro_fast_infrastructure_description")}</p>
        </div>
    </div>
</div>
<div className="grid md:grid-cols-3 max-w-screen-lg mx-auto gap-10 mt-16 px-5">
    <div ref={ref4}
className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible4 ? "opacity-100" : "opacity-0"}`}
style={{ transitionDelay: isVisible3 ? "0.2s" : "0s" }}
>
<div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 150 250"
            className="w-full h-full"
          >
           <g id="digitalization">
              <g id="Group_13">
                <path id="Vector_13" fill="#9AC439" d="M172.51,77.508c-7.7,0-14.25,5.001-16.58,11.924h-44.4v11.133h44.4
                  c6.53,18.513,33.679,14.301,34.07-5.567C190,85.354,182.15,77.508,172.51,77.508z"/>
                <path id="Vector_14" fill="#9AC439" d="M148.66,125.206c-2.8,0-5.44,0.663-7.79,1.834l-12.13-12.128
                  c-1.04-1.044-2.45-1.631-3.93-1.631h-13.28v11.133h10.97l10.5,10.498c-5.85,11.321,2.86,25.446,15.66,25.275
                  C171.83,159.309,171.82,126.082,148.66,125.206z"/>
                <path id="Vector_15" fill="#43277E" d="M30.52,100.566H0c0.61,10.643,2.97,20.955,6.94,30.603h27.15
                  C32.05,121.434,30.84,111.133,30.52,100.566z"/>
                <path id="Vector_16" fill="#43277E" d="M34.09,58.832H6.94C2.97,68.48,0.61,78.792,0,89.435h30.52
                  C30.84,78.868,32.05,68.567,34.09,58.832z"/>
                <path id="Vector_17" fill="#43277E" d="M57.4,7.637C30.61,19.121,17.23,39.331,12.41,47.699h24.45
                  C40.63,34.678,47.04,19.561,57.4,7.637z"/>
                <path id="Vector_18" fill="#43277E" d="M12.41,142.301c4.82,8.367,18.2,28.577,44.99,40.062
                  c-10.37-11.926-16.77-27.041-20.54-40.062H12.41z"/>
                <path id="Vector_19" fill="#43277E" d="M94.83,0C73.46,0,56.99,20.893,48.48,47.698h51.92V5.566C100.4,2.492,97.91,0,94.83,0z"/>
                <path id="Vector_20" fill="#43277E" d="M94.83,189.999c3.08,0,5.57-2.493,5.57-5.567v-42.131H48.48
                  C57,169.144,73.48,189.999,94.83,189.999z"/>
                <path id="Vector_21" fill="#43277E" d="M41.66,89.435h58.74V58.832H45.48C43.3,68.476,42,78.794,41.66,89.435z"/>
                <path id="Vector_22" fill="#9AC439" d="M128.74,75.086l12.13-12.128c11.32,5.852,25.45-2.855,25.28-15.657
                  c0-9.644-7.85-17.491-17.49-17.491c-12.8-0.17-21.51,13.955-15.66,25.276l-10.5,10.498h-10.97v11.132h13.28
                  C126.29,76.716,127.7,76.13,128.74,75.086z"/>
                <path id="Vector_23" fill="#43277E" d="M45.48,131.169h54.92v-30.603H41.66C42,111.208,43.3,121.525,45.48,131.169z"/>
              </g>
            </g>
            </svg>
      </div>
        <div>
        <h3 className="font-semibold text-xl text-bitben-violet">{t("pro_flexability_title")}</h3>
            <p className="mt-1 text-bitben-violet">{t("pro_flexability_description")}</p>
        </div>
    </div>

    <div ref={ref5}
className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible5 ? "opacity-100" : "opacity-0"}`}
style={{ transitionDelay: isVisible4 ? "0.2s" : "0s" }}>
        
        <div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            className="w-full h-full"
          >

<g id="laptop_3">
	<g id="Group_200_x5F_2">
		<g id="Group_18">
			<g id="Group_19">
				<path id="Vector_41" fill="#9AC439" d="M67.17,55.668v10.107c4.72-1.681,8.43-5.391,10.11-10.107H67.17z"/>
			</g>
		</g>
		<g id="Group_20">
			<g id="Group_21">
				<path id="Vector_42" fill="#9AC439" d="M88.87,55.668c-2.21,10.889-10.81,19.483-21.7,21.702v11.696h33.4V55.668H88.87z"/>
			</g>
		</g>
		<g id="Group_22">
			<g id="Group_23">
				<path id="Vector_43" fill="#9AC439" d="M61.61,33.398c-9.21,0-16.7,7.491-16.7,16.7c0,7.247,4.66,13.368,11.13,15.674V50.098
					c0-3.077,2.49-5.567,5.57-5.567h15.67C74.97,38.066,68.85,33.398,61.61,33.398z"/>
			</g>
		</g>
		<g id="Group_24">
			<g id="Group_25">
				<path id="Vector_44" fill="#43277D" d="M173.29,0H16.69c-3.08,0-5.57,2.49-5.57,5.566v105.762h167.739V5.566
					C178.859,2.49,176.37,0,173.29,0z M111.69,94.629c0,3.077-2.49,5.566-5.57,5.566H61.59c-3.07,0-5.56-2.489-5.56-5.566V77.366
					C43.34,74.78,33.76,63.537,33.76,50.098c0-15.346,12.49-27.832,27.83-27.832c13.44,0,24.69,9.577,27.27,22.265h17.26
					c3.08,0,5.57,2.49,5.57,5.567V94.629z M151.03,100.195h-22.64c-3.08,0-5.57-2.489-5.57-5.566s2.49-5.567,5.57-5.567h22.64
					c3.07,0,5.56,2.49,5.56,5.567S154.1,100.195,151.03,100.195z M151.03,77.93h-22.64c-3.08,0-5.57-2.489-5.57-5.567
					c0-3.076,2.49-5.566,5.57-5.566h22.64c3.07,0,5.56,2.49,5.56,5.566C156.59,75.44,154.1,77.93,151.03,77.93z M151.03,55.664
					h-22.64c-3.08,0-5.57-2.49-5.57-5.566c0-3.077,2.49-5.567,5.57-5.567h22.64c3.07,0,5.56,2.49,5.56,5.567
					C156.59,53.174,154.1,55.664,151.03,55.664z M151.03,33.398h-22.64c-3.08,0-5.57-2.489-5.57-5.566s2.49-5.566,5.57-5.566h22.64
					c3.07,0,5.56,2.489,5.56,5.566S154.1,33.398,151.03,33.398z"/>
			</g>
		</g>
		<g id="Group_26">
			<g id="Group_27">
				<path id="Vector_45" fill="#43277D" d="M0,122.461v16.699c0,3.077,2.49,5.566,5.57,5.566h178.86c3.08,0,5.57-2.489,5.57-5.566
					v-16.699H0z"/>
			</g>
		</g>
	</g>
</g>
</svg>
</div>
        <div>
        <h3 className="font-semibold text-xl text-bitben-violet">{t("pro_usability_title")}</h3>
            <p className="mt-1 text-bitben-violet">{t("pro_usability_description")}</p>
        </div>
    </div>


    <div ref={ref6}
className={`flex gap-4 items-start flex-col transition-opacity ease-in duration-500 ${isVisible6 ? "opacity-100" : "opacity-0"}`}
style={{ transitionDelay: isVisible5 ? "0.2s" : "0s" }}>
        
        {/* support icon */}
        <div className="flex items-center justify-center w-full h-20 bg-gray rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            className="w-full h-full"
          >

        <g id="help_desk_x5F_2">
	<g id="Group_30">
		<path id="Vector_35" fill="#43277D" d="M120.605,131.738h-9.346c-0.958,14.483-13.04,25.977-27.763,25.977
			s-26.804-11.493-27.763-25.977h-9.346C20.809,131.738,0,152.547,0,178.125v6.309C0,187.508,2.492,190,5.566,190h155.86
			c3.074,0,5.566-2.492,5.566-5.566v-6.309C166.992,152.547,146.183,131.738,120.605,131.738z"/>
		<path id="Vector_36" fill="#9AC439" d="M35.258,83.496V61.23c-6.149,0-11.133,4.985-11.133,11.133
			C24.125,78.512,29.109,83.496,35.258,83.496z"/>
		<path id="Vector_37" fill="#9AC439" d="M98.111,36.884c2.005-2.005,5.199-2.182,7.413-0.41l17.03,13.624h9.18v-1.856
			C131.734,21.599,110.136,0,83.492,0C56.849,0,35.25,21.599,35.25,48.242v1.856h30.962C78.177,50.098,89.651,45.345,98.111,36.884z
			"/>
		<path id="Vector_38" fill="#9AC439" d="M66.797,126.902v2.981c0,9.224,7.476,16.699,16.699,16.699s16.699-7.476,16.699-16.699
			v-2.981c-5.206,1.927-10.831,2.981-16.699,2.981S72.003,128.829,66.797,126.902z"/>
		<path id="Vector_39" fill="#43277D" d="M81.645,102.049c0-3.074,2.492-5.565,5.566-5.565h30.308
			c1.986-4.546,3.09-9.565,3.09-14.844V62.791l-18.313-14.65c-10.101,8.473-22.735,13.088-36.077,13.088H46.391v20.41
			c0,20.495,16.614,37.11,37.109,37.11c10.38,0,19.763-4.264,26.498-11.133H87.211C84.137,107.616,81.645,105.124,81.645,102.049z"
			/>
		<path id="Vector_40" fill="#9AC439" d="M131.736,83.496h5.567v3.711c0,5.116-4.162,9.277-9.278,9.277h-10.512
			c-1.82,4.166-4.383,7.933-7.521,11.133h18.033c11.255,0,20.411-9.156,20.411-20.41V72.363c0-6.148-4.985-11.133-11.133-11.133
			h-5.567V83.496z"/>
	</g>
</g>
</svg>
</div>
        <div>
        <h3 className="font-semibold text-xl text-bitben-violet">{t("pro_support_title")}</h3>
        <p className="mt-1 text-bitben-violet">{t("pro_support_description")}</p>
        </div>
    </div>
</div>
</>

  );
}